import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + '/me.png'} alt="mark" />
        <p>
          <code>Mark Fl<a class="nostyle" href="https://bidder.markflorian.com">o</a>rian</code>
        </p>
        <p>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/markflorian/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          |
          <a
            className="App-link"
            href="https://github.com/marknf"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
          |
          <a
            className="App-link"
            href="https://www.instagram.com/marknflorian/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
